import admin from "./admin";

const stale = [
  {
    path: "/",
    redirect: "login",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/typography",
    name: "typography",
    component: () => import("@/views/typography/Typography.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/icons",
    name: "icons",
    component: () => import("@/views/icons/Icons.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/cards",
    name: "cards",
    component: () => import("@/views/cards/Card.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/simple-table",
    name: "simple-table",
    component: () => import("@/views/simple-table/SimpleTable.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/form-layouts",
    name: "form-layouts",
    component: () => import("@/views/form-layouts/FormLayouts.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/account-settings",
    name: "pages-account-settings",
    component: () => import("@/views/pages/account-settings/AccountSettings.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/login",
    name: "pages-login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "blank",
      allowAnonymous: true,
    },
  },
  {
    path: "/register",
    name: "pages-register",
    component: () => import("@/views/auth/Register.vue"),
    meta: {
      layout: "blank",
      allowAnonymous: true,
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      layout: "blank",
      allowAnonymous: true,
    },
  },
  {
    path: "/email/verify",
    name: "Verify-Email",
    component: () => import("@/views/auth/VerifyEmail.vue"),
    meta: {
      allowAnonymous: true,
      layout: "blank",
    },
    hidden: true,
  },
  {
    path: "/verified",
    name: "Verified-Email",
    component: () => import("@/views/auth/VerifiedEmail.vue"),
    meta: {
      allowAnonymous: true,
      layout: "blank",
    },
    hidden: true,
  },
  {
    path: "/help",
    name: "help",
    component: () => import("@/views/pages/Help/Index.vue"),
    meta: {
      allowAnonymous: true,
      layout: "blank",
    },
    hidden: true,
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error.vue"),
    meta: {
      layout: "blank",
      allowAnonymous: true,
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const routes = [];

admin.forEach(route => {
  routes.push(route);
});

stale.forEach(route => {
  routes.push(route);
});

export default routes;
