export default {
  ROLE_ADMIN: 'administrator',
  ROLE_DEV: 'developer',
  ROLE_CUSTOMER: 'customer',
  PERMISSION_MANAGE_ASSETS: 'manage assets',
  PERMISSION_MANAGE_MISSION: 'manage missions',
  PERMISSION_MANAGE_USER: 'manage users',
  PERMISSION_MANAGE_SETTINGS: 'manage settings',
  PERMISSION_MANAGE_SYSTEM: 'manage system',
  PERMISSION_MANAGE_ROLE: 'manage permission role',
};
