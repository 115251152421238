export default {
  IS_LOADING: 'setIsLoading',
  ERROR_OCCURRED: 'setErrorOccurred',
  AUTHENTICATED_USER: 'setUserInfo',
  SET_PERMISSIONS: 'setPermissions',
  SET_ROLES: 'setRoles',
  SET_ROUTES: 'setRoutes',
  SET_USERS: 'setUsers',
  SET_CLIENTS: 'setClients',
  SET_MISSION_DROPDOWNS: 'setMissionDropdowns',
  ALERTS: 'setAlerts',
};
