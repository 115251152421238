import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps'; // Google Maps package

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAWXp2Q_BC2YjwEZLbtNij1IyJA1gyDKBY',
    libraries: 'places',
    region: 'ZA',
  },
});
