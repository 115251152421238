import NAMESPACE from './namespaces';
import MUTATION from './mutations';
import ACTION from './actions';
import HTTP from './http';
import URI from './uri';
import ACL from './acl';

export default {
  NAMESPACE,
  MUTATION,
  ACTION,
  HTTP,
  URI,
  ACL,
};
