const getters = {
  loader: state => state.system.loader,
  alerts: state => state.system.alerts,
  error_occurred: state => state.system.error_occurred,
  userinfo: state => state.auth.userinfo,
  roles: state => state.auth.roles,
  permissions: state => state.auth.permissions,
  dropdown: state => state.system.dropdown,
  fileUri: state => state.system.fileUri,
};
export default getters;
