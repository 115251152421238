export default {
  /// Auth
  REGISTER: 'registration',
  LOGIN: 'login',
  FORGOT: 'forgot',
  AUTHENTICATED_USER: 'userInfo',
  LOGOUT: 'logout',

  // Utility
  IS_LOADING: 'isLoading',
  ERROR_OCCURRED: 'errorOccurred',
  GEN_ROUTES: 'generateRoute',
  FORGOT_PASS: 'forgotPass',
  MISSION_DEPENDENCIES: 'missionDependencies',
  ALERTS: 'countAlerts',
};
