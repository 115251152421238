import client from "@/core/api";
import IYA from "@/core/contract";
import { routesRestricted, staticRoutes } from "@/router";

/// //////////////////////////////
/// //// Utilities
// eslint-disable-next-line
function canAccessRoute(roles, permissions, route) {
  if (route.meta) {
    let hasRole = true;
    let hasPermission = true;
    if (route.meta.roles || route.meta.permissions) {
      hasRole = false;
      hasPermission = false;
      if (route.meta.roles) {
        hasRole = roles.some(role => route.meta.roles.includes(role));
      }

      if (route.meta.permissions) {
        hasPermission = permissions.some(permission => route.meta.permissions.includes(permission));
      }
    }

    return hasRole || hasPermission;
  }

  // If no meta.roles/meta.permissions inputted - the route should be accessible
  return true;
}

function filterDynamicRoutes(routes, roles, permissions) {
  const res = [];

  routes.forEach(route => {
    const tmp = { ...route };
    if (canAccessRoute(roles, permissions, route)) {
      if (tmp.children) {
        tmp.children = filterDynamicRoutes(tmp.children, roles, permissions);
      }
      res.push(tmp);
    }
  });

  return res;
}

const state = {
  userinfo: {},
  roles: [],
  permissions: [],
  routes: [],
};

const mutations = {
  // eslint-disable-next-line
  [IYA.MUTATION.AUTHENTICATED_USER]: (state, data) => {
    state.userinfo = data;
  },
  // eslint-disable-next-line
  [IYA.MUTATION.SET_ROLES]: (state, data) => {
    state.roles = data;
  },
  // eslint-disable-next-line
  [IYA.MUTATION.SET_PERMISSIONS]: (state, data) => {
    state.permissions = data;
  },
  // eslint-disable-next-line
  [IYA.MUTATION.SET_ROUTES]: (state, routes) => {
    state.routes = staticRoutes.concat(routes);
  },
};

const actions = {
  [IYA.ACTION.GEN_ROUTES]({ commit }, { roles, permissions }) {
    let accessedRoutes;
    if (roles.includes(IYA.ACL.ROLE_ADMIN) || roles.includes(IYA.ACL.ROLE_DEV)) {
      accessedRoutes = routesRestricted || [];
    } else {
      accessedRoutes = filterDynamicRoutes(routesRestricted, roles, permissions);
    }
    commit(IYA.MUTATION.SET_ROUTES, accessedRoutes);
    return accessedRoutes;
  },

  /// ////////////////////////
  /// Register
  // eslint-disable-next-line
  [IYA.ACTION.REGISTER]({}, registration) {
    return client[IYA.ACTION.REGISTER](registration);
  },
  // eslint-disable-next-line
  [IYA.ACTION.RESEND_VERIFICATION]({}) {
    return client[IYA.ACTION.RESEND_VERIFICATION]();
  },

  /// /////////////////////////
  /// Login
  // eslint-disable-next-line
  [IYA.ACTION.LOGIN]({}, login) {
    return client[IYA.ACTION.LOGIN](login);
  },
  [IYA.ACTION.AUTHENTICATED_USER]({ commit }) {
    return client[IYA.ACTION.AUTHENTICATED_USER]().then(response => {
      if (response.data) {
        if (response.status === 401) {
          return false;
        }
        const { roles, permissions } = response.data.data;
        // eslint-disable-next-line
        delete response.data.data.roles;
        // eslint-disable-next-line
        delete response.data.data.permissions;
        commit(IYA.MUTATION.AUTHENTICATED_USER, response.data.data);
        commit(IYA.MUTATION.SET_ROLES, roles);
        commit(IYA.MUTATION.SET_PERMISSIONS, permissions);
      }

      return { status: response.status, message: response.message };
    });
  },
  // eslint-disable-next-line
  [IYA.ACTION.LOGOUT]({}) {
    return client[IYA.ACTION.LOGOUT]();
  },
  // eslint-disable-next-line
  [IYA.ACTION.FORGOT_PASS]({}, email) {
    return client[IYA.ACTION.FORGOT_PASS](email);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
