import "./bootstrap";
import util from "@/core/utils/misc";
import http from "@/core/contract/http";

// Create axios instance
const service = window.axios.create({
  baseURL: "https://itpv.lenzmediasa.co.za", // "http://127.0.0.1:8000",
  timeout: 50000,
  headers: {
    Accept: "application/json",
  },
});

// Request intercepter
service.interceptors.request.use(
  config => {
    if (util.isLoggedIn) {
      // eslint-disable-next-line
      config.headers.Authorization = `Bearer ${util.getToken()}`; // Set JWT token
    }

    return config;
  },
  error => {
    // Swal.fire("Request Interupted!", error.message, "error");
    console.log(`Request Interupted! ${error.message}`);

    return { message: error.message, data: [], status: 403 };
  },
);

service.interceptors.response.use(
  response => {
    const { data } = response;
    const { status } = response;
    const message = response.statusText;

    console.log(response);

    return { data, status, message };
  },
  error => {
    console.log(error);
    const { data } = error.response;
    const { status } = error.response;
    const message = error.response.statusText;
    console.log(status);
    switch (status) {
      case http.INTERNAL_SERVER:
        util.errorOccurred({ snackbar: true, message: "Internal server error" });
        break;
      case http.FORBIDDEN:
        util.errorOccurred({ snackbar: true, message: message });
        break;
      case http.HTTP_FAILED_DEPENDENCY:
        util.errorOccurred({ snackbar: true, message: message });
        break;
      case http.UNAUTHORISED:
        util.errorOccurred({ snackbar: true, message: message });
        break;
      default:
        console.log();
    }

    return { message, data, status };
  },
);

export default service;
