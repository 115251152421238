<template>
  <v-snackbar v-model="snackbar" :timeout="-1" bottom color="error">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "SnackBarError",
  props: {
    message: {
      type: String,
      default: "Unknown Error Occurred",
    },
    snackbar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
