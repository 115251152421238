import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./modules/static";
import routesAdmin from "./modules/admin";
import util from "@/core/utils/misc";
import store from "@/store";
import IYA from "@/core/contract";

Vue.use(VueRouter);

export const staticRoutes = routes;
export const routesRestricted = routesAdmin;

const router = new VueRouter({
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: staticRoutes,
});

/// ////////////////////////////////////////////////////////////////////////////
/// //////  Router Configurations
/// //////  Permissions Routing
/// //////  NProgress - (Loader)
/// //////  Role routing Specific
/// //////  Basically, Its a smart Routing system configuration
/// ////////////////////////////////////////////////////////////////////////
router.beforeEach(async (to, from, next) => {
  util.isLoading({ active: true, title: "Wait while prepare." });
  switch (util.isLoggedIn()) {
    case true:
      if (store.getters.roles && store.getters.roles.length > 0) {
        next();
      }
      await store.dispatch(`${IYA.NAMESPACE.AUTH}/${IYA.ACTION.AUTHENTICATED_USER}`).then(response => {
        if (!response) {
          util.removeToken();
          next({
            name: "pages-login",
            query: { redirect: to.fullPath },
          });
        }
      });
      next();
      break;
    default:
      if (!to.meta.allowAnonymous) {
        util.removeToken();
        next({
          name: "pages-login",
          query: { redirect: to.fullPath },
        });
      } else {
        next();
      }
      break;
  }
  util.isLoading();
});

export default router;
