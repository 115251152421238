import Vue from "vue";
import contract from "@/core/contract";

const domain = "https://itpv.lenzmediasa.co.za/"; // "http://127.0.0.1:8000/";

Vue.prototype.$IYA = contract;
Vue.prototype.$MEDIA = `${domain}media/`;
Vue.prototype.$SYS_ICO = `${domain}system/icons/`;
Vue.prototype.$DOMAIN = `${domain}`;
Vue.prototype.$PDFPLACEHLODER = `${domain}media/system/images/pdfthumb.png`;
