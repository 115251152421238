import IYA from "@/core/contract";

export default [
  {
    path: "/aircraft/browse",
    name: "AircraftBrowse",
    component: () => import("@/views/pages/Aircraft/AircraftBrowse.vue"),
    meta: {
      title: "Aircraft",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS],
    },
  },

  {
    path: "/alerts",
    name: "Alerts",
    component: () => import("@/views/pages/Alerts/Index.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
    hidden: true,
  },

  {
    path: "/aircraft/read/:id",
    name: "Aircraft-Read",
    component: () => import("@/views/pages/Aircraft/AircraftRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
    hidden: true,
  },
  {
    path: "/aircraft/add",
    name: "Aircraft-Add",
    component: () => import("@/views/pages/Aircraft/AircraftAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
    hidden: true,
  },
  {
    path: "/aircraft_maintenances/browse",
    name: "AircraftMaintenanceBrowse",
    component: () => import("@/views/pages/AircraftMaintenance/AircraftMaintenanceBrowse.vue"),
    meta: {
      title: "Aircraft Maintenances",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS],
    },
  },

  {
    path: "/aircraft_maintenances/read/:id",
    name: "AircraftMaintenance-Read",
    component: () => import("@/views/pages/AircraftMaintenance/AircraftMaintenanceRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS] },
  },
  {
    path: "/aircraft_maintenances/add",
    name: "AircraftMaintenance-Add",
    component: () => import("@/views/pages/AircraftMaintenance/AircraftMaintenanceAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS] },
  },
  {
    path: "/aircraft_statuses/browse",
    name: "AircraftStatusBrowse",
    component: () => import("@/views/pages/AircraftStatus/AircraftStatusBrowse.vue"),
    meta: {
      title: "Aircraft Statuses",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS],
    },
  },

  {
    path: "/aircraft_statuses/read/:id",
    name: "AircraftStatus-Read",
    component: () => import("@/views/pages/AircraftStatus/AircraftStatusRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS] },
  },
  {
    path: "/aircraft_statuses/add",
    name: "AircraftStatus-Add",
    component: () => import("@/views/pages/AircraftStatus/AircraftStatusAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS] },
  },
  {
    path: "/aircraft_types/browse",
    name: "AircraftTypeBrowse",
    component: () => import("@/views/pages/AircraftType/AircraftTypeBrowse.vue"),
    meta: {
      title: "Aircraft Types",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS],
    },
  },

  {
    path: "/aircraft_types/read/:id",
    name: "AircraftType-Read",
    component: () => import("@/views/pages/AircraftType/AircraftTypeRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS] },
  },
  {
    path: "/aircraft_types/add",
    name: "AircraftType-Add",
    component: () => import("@/views/pages/AircraftType/AircraftTypeAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SETTINGS] },
  },
  {
    path: "/batteries/browse",
    name: "BatteryBrowse",
    component: () => import("@/views/pages/Battery/BatteryBrowse.vue"),
    meta: {
      title: "Batteries",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS],
    },
  },

  {
    path: "/batteries/read/:id",
    name: "Battery-Read",
    component: () => import("@/views/pages/Battery/BatteryRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
  },
  {
    path: "/batteries/add",
    name: "Battery-Add",
    component: () => import("@/views/pages/Battery/BatteryAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
  },
  {
    path: "/cameras/browse",
    name: "CameraBrowse",
    component: () => import("@/views/pages/Camera/CameraBrowse.vue"),
    meta: {
      title: "Cameras",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS],
    },
  },

  {
    path: "/cameras/read/:id",
    name: "Camera-Read",
    component: () => import("@/views/pages/Camera/CameraRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
  },
  {
    path: "/cameras/add",
    name: "Camera-Add",
    component: () => import("@/views/pages/Camera/CameraAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
  },
  {
    path: "/permissions/browse",
    name: "PermissionBrowse",
    component: () => import("@/views/pages/Permission/PermissionBrowse.vue"),
    meta: {
      title: "Permissions",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_ROLE],
    },
  },

  {
    path: "/permissions/read/:id",
    name: "Permission-Read",
    component: () => import("@/views/pages/Permission/PermissionRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ROLE] },
  },
  {
    path: "/permissions/add",
    name: "Permission-Add",
    component: () => import("@/views/pages/Permission/PermissionAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ROLE] },
  },
  {
    path: "/radios/browse",
    name: "RadioBrowse",
    component: () => import("@/views/pages/Radio/RadioBrowse.vue"),
    meta: {
      title: "Radios",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS],
    },
  },

  {
    path: "/radios/read/:id",
    name: "Radio-Read",
    component: () => import("@/views/pages/Radio/RadioRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
  },
  {
    path: "/radios/add",
    name: "Radio-Add",
    component: () => import("@/views/pages/Radio/RadioAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ASSETS] },
  },
  {
    path: "/roles/browse",
    name: "RoleBrowse",
    component: () => import("@/views/pages/Role/RoleBrowse.vue"),
    meta: {
      title: "Roles",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_ROLE],
    },
  },

  {
    path: "/roles/read/:id",
    name: "Role-Read",
    component: () => import("@/views/pages/Role/RoleRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ROLE] },
  },
  {
    path: "/roles/add",
    name: "Role-Add",
    component: () => import("@/views/pages/Role/RoleAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_ROLE] },
  },
  {
    path: "/users/browse",
    name: "UserBrowse",
    component: () => import("@/views/pages/User/UserBrowse.vue"),
    meta: {
      title: "Users",
      icon: "mdi-police-badge",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_USER],
    },
  },

  {
    path: "/users/read/:id",
    name: "User-Read",
    component: () => import("@/views/pages/User/UserRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_USER] },
  },
  {
    path: "/users/add",
    name: "User-Add",
    component: () => import("@/views/pages/User/UserAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_USER] },
  },

  // {
  //   path: '/weather/browse',
  //   name: 'WeatherBrowse',
  //   component: () => import('@/views/pages/Weather/WeatherBrowse.vue'),
  //   meta: { title: 'Weather', icon: 'mdi-police-badge', allowAnonymous: false },
  // },

  // {
  //   path: '/weather/read/:id',
  //   name: 'Weather-Read',
  //   component: () => import('@/views/pages/Weather/WeatherRead.vue'),
  //   hidden: true,
  //   meta: { allowAnonymous: false },
  // },
  // {
  //   path: '/weather/add',
  //   name: 'Weather-Add',
  //   component: () => import('@/views/pages/Weather/WeatherAdd.vue'),
  //   hidden: true,
  //   meta: { allowAnonymous: false },
  // },

  {
    path: "/mission/browse",
    name: "MissionBrowse",
    component: () => import("@/views/pages/Mission/MissionBrowse.vue"),
    meta: {
      title: "Mission",
      icon: "mdi-shield-airplane",
      allowAnonymous: false,
      permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION],
    },
  },
  {
    path: "/mission/read/:id",
    name: "Mission-Read",
    component: () => import("@/views/pages/Mission/MissionRead.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
  },
  {
    path: "/mission/add",
    name: "Mission-Add",
    component: () => import("@/views/pages/Mission/MissionAdd.vue"),
    hidden: true,
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
  },

  {
    path: "/air-traffic-controls/browse",
    name: "AirTrafficControlBrowse",
    component: () => import("@/views/pages/AirTrafficControl/AirTrafficControlBrowse.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
  },
  {
    path: "/air-traffic-controls/read/:id",
    name: "AirTrafficControl-Read",
    component: () => import("@/views/pages/AirTrafficControl/AirTrafficControlRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },
  {
    path: "/air-traffic-controls/add",
    name: "AirTrafficControl-Add",
    component: () => import("@/views/pages/AirTrafficControl/AirTrafficControlAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },

  {
    path: "/chart-upload-items/browse",
    name: "ChartUploadItemBrowse",
    component: () => import("@/views/pages/ChartUploadItem/ChartUploadItemBrowse.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
  },
  {
    path: "/chart-upload-items/read/:id",
    name: "ChartUploadItem-Read",
    component: () => import("@/views/pages/ChartUploadItem/ChartUploadItemRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
    hidden: true,
  },
  {
    path: "/chart-upload-items/add",
    name: "ChartUploadItem-Add",
    component: () => import("@/views/pages/ChartUploadItem/ChartUploadItemAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
    hidden: true,
  },

  {
    path: "/chart-uploads/browse",
    name: "ChartUploadBrowse",
    component: () => import("@/views/pages/ChartUpload/ChartUploadBrowse.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
  },
  {
    path: "/chart-uploads/read/:id",
    name: "ChartUpload-Read",
    component: () => import("@/views/pages/ChartUpload/ChartUploadRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },
  {
    path: "/chart-uploads/add",
    name: "ChartUpload-Add",
    component: () => import("@/views/pages/ChartUpload/ChartUploadAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },

  {
    path: "/emergencies/browse",
    name: "EmergencyBrowse",
    component: () => import("@/views/pages/Emergency/EmergencyBrowse.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
  },
  {
    path: "/emergencies/read/:id",
    name: "Emergency-Read",
    component: () => import("@/views/pages/Emergency/EmergencyRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },
  {
    path: "/emergencies/add",
    name: "Emergency-Add",
    component: () => import("@/views/pages/Emergency/EmergencyAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },

  {
    path: "/emergency-items/browse",
    name: "EmergencyItemBrowse",
    component: () => import("@/views/pages/EmergencyItem/EmergencyItemBrowse.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
  },
  {
    path: "/emergency-items/read/:id",
    name: "EmergencyItem-Read",
    component: () => import("@/views/pages/EmergencyItem/EmergencyItemRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
    hidden: true,
  },
  {
    path: "/emergency-items/add",
    name: "EmergencyItem-Add",
    component: () => import("@/views/pages/EmergencyItem/EmergencyItemAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
    hidden: true,
  },

  {
    path: "/folio-entries/browse",
    name: "FolioEntryBrowse",
    component: () => import("@/views/pages/FolioEntry/FolioEntryBrowse.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
  },
  {
    path: "/folio-entries/read/:id",
    name: "FolioEntry-Read",
    component: () => import("@/views/pages/FolioEntry/FolioEntryRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },
  {
    path: "/folio-entries/add",
    name: "FolioEntry-Add",
    component: () => import("@/views/pages/FolioEntry/FolioEntryAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },

  {
    path: "/risk-management/browse",
    name: "RiskManagementBrowse",
    component: () => import("@/views/pages/RiskManagement/RiskManagementBrowse.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
  },
  {
    path: "/risk-management/read/:id",
    name: "RiskManagement-Read",
    component: () => import("@/views/pages/RiskManagement/RiskManagementRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },
  {
    path: "/risk-management/add",
    name: "RiskManagement-Add",
    component: () => import("@/views/pages/RiskManagement/RiskManagementAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_MISSION] },
    hidden: true,
  },

  {
    path: "/risk-management-items/browse",
    name: "RiskManagementItemBrowse",
    component: () => import("@/views/pages/RiskManagementItem/RiskManagementItemBrowse.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
  },
  {
    path: "/risk-management-items/read/:id",
    name: "RiskManagementItem-Read",
    component: () => import("@/views/pages/RiskManagementItem/RiskManagementItemRead.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
    hidden: true,
  },
  {
    path: "/risk-management-items/add",
    name: "RiskManagementItem-Add",
    component: () => import("@/views/pages/RiskManagementItem/RiskManagementItemAdd.vue"),
    meta: { allowAnonymous: false, permissions: [IYA.ACL.PERMISSION_MANAGE_SYSTEM] },
    hidden: true,
  },
];
