<template>
  <v-app>
    <lights-loader :overlay="loader.active" :title="loader.title"></lights-loader>
    <snack-bar-error :snackbar="error_occurred.snackbar" :message="error_occurred.message"></snack-bar-error>
    <v-app-bar app flat fixed color="white">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <v-spacer></v-spacer>
          <!-- Left Content -->
          <span v-if="user.first_name">
            <!-- Right Content -->
            <theme-switcher></theme-switcher>
            <v-btn icon small class="ms-3">
              <v-icon>
                {{ icons.mdiBellOutline }}
              </v-icon>
            </v-btn>
            <app-bar-user-menu></app-bar-user-menu>
          </span>
          <span v-else>
            <router-link to="/register" class="text-decoration-none mx-5 px-2">Register</router-link>
            <router-link to="/login" class="text-decoration-none mx-5 px-2">Login</router-link>
            <router-link to="/help" class="text-decoration-none mx-5 px-2">Help</router-link>
          </span>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mdiMagnify, mdiBellOutline, mdiGithub } from "@mdi/js";
import LightsLoader from "@/components/Loader.vue";
import SnackBarError from "@/components/SnackBarError.vue";
import ThemeSwitcher from "./components/ThemeSwitcher.vue";
import AppBarUserMenu from "./components/AppBarUserMenu.vue";

export default {
  components: {
    LightsLoader,
    ThemeSwitcher,
    AppBarUserMenu,
    SnackBarError,
  },
  // eslint-disable-next-line
  computed: {
    loader() {
      return this.$store.getters.loader;
    },
    error_occurred() {
      return this.$store.getters.error_occurred;
    },
    user() {
      return this.$store.getters.userinfo;
    },
  },
  data() {
    return {
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    };
  },
};
</script>

<style></style>
