import Request from '@/core/api/request';
import IYA from '@/core/contract';

export default {
  /// Auth
  [IYA.ACTION.REGISTER](registration) {
    console.log(registration);

    return Request.post(IYA.URI.REGISTER, registration);
  },
  [IYA.ACTION.RESEND_VERIFICATION](registration) {
    return Request.post(IYA.URI.RESEND__VERIFICATION, registration);
  },
  [IYA.ACTION.LOGIN](login) {
    return Request.post(IYA.URI.LOGIN, login);
  },
  [IYA.ACTION.AUTHENTICATED_USER]() {
    return Request.post(IYA.URI.AUTHENTICATED__USER);
  },
  [IYA.ACTION.LOGOUT]() {
    return Request.get(IYA.URI.LOGOUT);
  },
  [IYA.ACTION.FORGOT_PASS](email) {
    return Request.post(IYA.URI.FORGOT__PASS, email);
  },

  /// /////////////////////////
  /// Profile
  [IYA.ACTION.PROFILE_UPDATE](profile) {
    return Request.post(IYA.URI.PROFILE__UPDATE, profile);
  },
  [IYA.ACTION.PROFILE_UPDATE_PROPIC](profile) {
    return Request.post(IYA.URI.PROFILE__UPDATE__PROPIC, profile);
  },
  [IYA.ACTION.PROFILE_UPDATE_PASSWORD](profile) {
    return Request.post(IYA.URI.PROFILE__UPDATE__PASSWORD, profile);
  },

  // Generic
  [IYA.ACTION.MISSION_DEPENDENCIES]() {
    return Request.get(IYA.URI.MISSION__DEPENDENCIES);
  },

  [IYA.ACTION.ALERTS]() {
    return Request.get(IYA.URI.ALERTS);
  },
};
