import Cookies from 'js-cookie';
import store from '@/store';
import IYA from '@/core/contract';

const Jwt = 'Token';

function isLoading(loading = { active: false, title: 'Processing...' }) {
  store.dispatch(`${IYA.NAMESPACE.SYS}/${IYA.ACTION.IS_LOADING}`, loading);
}

function errorOccurred(error = { snackbar: false, message: '' }) {
  store.dispatch(`${IYA.NAMESPACE.SYS}/${IYA.ACTION.ERROR_OCCURRED}`, error);
}

function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

function removeToken() {
  Cookies.remove(Jwt, { secure: true, sameSite: 'lax' });
}

function setToken(token) {
  removeToken();
  Cookies.set(Jwt, token, { expires: 1, secure: true, sameSite: 'lax' });
}

function getToken() {
  return isBlank(Cookies.get(Jwt)) ? '' : Cookies.get(Jwt);
}

function isLoggedIn() {
  return !!getToken();
}

export default {
  isLoading,
  isBlank,
  setToken,
  getToken,
  removeToken,
  isLoggedIn,
  errorOccurred,
};
