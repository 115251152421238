import store from '@/store';

export default {
  // eslint-disable-next-line
  inserted(el, binding, vnode) {
    const { value } = binding;
    const permissions = store.getters && store.getters.permissions;

    if (value && value instanceof Array && value.length > 0) {
      const requiredPermissions = value;
      const hasPermission = permissions.some(permission => requiredPermissions.includes(permission));

      if (!hasPermission) {
        // eslint-disable-next-line
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("Permissions are required! Example: v-permission=\"['manage user','manage permission']\"");
    }
  },
};
