<template>
  <v-dialog v-model="overlay" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-overlay :value="overlay" opacity="1" color="#347fc3">
      <v-container style="height: 400px;">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            <v-avatar>
              <v-img :src="require('@/assets/images/logos/logo.png')"></v-img>
            </v-avatar>
          </v-col>
          <v-col class="text-subtitle-1 text-center" cols="12">
            {{ title }}
          </v-col>
          <v-col cols="12">
            <v-progress-linear color="#fff" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-dialog>
</template>

<script>
export default {
  name: "IyaLoading",
  props: {
    title: {
      type: String,
      default: "Processing",
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
