import IYA from "@/core/contract";
import request from "@/core/api";

const state = {
  loader: {
    active: false,
    title: "Processing...",
  },
  error_occurred: { snackbar: false, message: "" },
  users: [],
  clients: [],
  dropdown: {},
  fileUri: "api/upload/file",
  alerts: 0,
};

const mutations = {
  // eslint-disable-next-line
  [IYA.MUTATION.IS_LOADING]: (state, data) => {
    state.loader = data;
  },
  // eslint-disable-next-line
  [IYA.MUTATION.ALERTS]: (state, data) => {
    state.alerts = data;
  },
  // eslint-disable-next-line
  [IYA.MUTATION.ERROR_OCCURRED]: (state, data) => {
    state.error_occurred = data;
  },
  // eslint-disable-next-line
  [IYA.MUTATION.SET_MISSION_DROPDOWNS]: (state, data) => {
    state.dropdown = data;
  },
};

const actions = {
  /// ////////////////////////
  [IYA.ACTION.IS_LOADING]({ commit }, data) {
    commit(IYA.MUTATION.IS_LOADING, data);
  },
  [IYA.ACTION.ERROR_OCCURRED]({ commit }, data) {
    commit(IYA.MUTATION.ERROR_OCCURRED, data);
  },
  [IYA.ACTION.ALERTS]({ commit }) {
    request[IYA.ACTION.ALERTS]().then(response => {
      if (response.data) {
        commit(IYA.MUTATION.ALERTS, response.data);
      }
    });
  },
  // eslint-disable-next-line
  [IYA.ACTION.MISSION_DEPENDENCIES]({ commit }) {
    return request[IYA.ACTION.MISSION_DEPENDENCIES]().then(response => {
      if (response.data) {
        const dropdown = response.data.data;
        commit(IYA.MUTATION.SET_MISSION_DROPDOWNS, dropdown);
      }

      return { status: response.status, message: response.message };
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
