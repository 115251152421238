export default {
  /// Auth
  REGISTER: "api/register",
  RESEND__VERIFICATION: "api/email/verification-notification",
  FORGOT: "api/forgot",
  LOGIN: "api/login",
  AUTHENTICATED__USER: "api/user",
  LOGOUT: "api/logout",
  FORGOT__PASS: "api/forgot-password",

  // Profile
  PROFILE__UPDATE: "api/profile/update",
  PROFILE__EDIT: "api/profile/edit",
  PROFILE__UPDATE__PROPIC: "api/profile/update/propic",
  PROFILE__UPDATE__PASSWORD: "api/password/update",
  USERS__APPROVE: "api/approve/user",

  // Generic
  MISSION__DEPENDENCIES: "api/mission_dependecies",
  ALERTS: "api/alert/alertor",
  USER__MED__DATES: "api/user/medical/dates",
  AIRCRAFT__DASH: "api/aircraft/dash",
  AIRCRAFT_MAINTENANCE: "api/aircraft/maintenance",
  AIRCRAFT__BATTERY__DASH: "api/battery/dash",
  ALERT__BROWSE: "/api/alert/browse",
  ALERT__MARK: "api/alert/update",

  WEATHER: "api/weather",
  WEATHER_REPORT: "api/weather/report",
  SAFETY: "api/safety",
  SAFETY_FETCH: "api/safety/fetch",

  // Dynamically generated

  ///   Mission
  MISSION__BROWSE: "api/mission/browse",
  MISSION__READ: "api/mission/read",
  MISSION__EDIT: "api/mission/edit",
  MISSION__ADD: "api/mission/add",
  MISSION__DELETE: "api/mission/delete",

  ///   Air Traffic Controls
  AIR_TRAFFIC_CONTROLS__BROWSE: "api/air_traffic_controls/browse",
  AIR_TRAFFIC_CONTROLS__READ: "api/air_traffic_controls/read",
  AIR_TRAFFIC_CONTROLS__EDIT: "api/air_traffic_controls/edit",
  AIR_TRAFFIC_CONTROLS__ADD: "api/air_traffic_controls/add",
  AIR_TRAFFIC_CONTROLS__DELETE: "api/air_traffic_controls/delete",

  ///   Aircraft
  AIRCRAFT__BROWSE: "api/aircraft/browse",
  AIRCRAFT__READ: "api/aircraft/read",
  AIRCRAFT__EDIT: "api/aircraft/edit",
  AIRCRAFT__ADD: "api/aircraft/add",
  AIRCRAFT__DELETE: "api/aircraft/delete",

  AIRCRAFT_FOLIO__ADD: "api/aircraft_folio/add",

  ///   Aircraft Maintenances
  AIRCRAFT_MAINTENANCES__BROWSE: "api/aircraft_maintenances/browse",
  AIRCRAFT_MAINTENANCES__READ: "api/aircraft_maintenances/read",
  AIRCRAFT_MAINTENANCES__EDIT: "api/aircraft_maintenances/edit",
  AIRCRAFT_MAINTENANCES__ADD: "api/aircraft_maintenances/add",
  AIRCRAFT_MAINTENANCES__DELETE: "api/aircraft_maintenances/delete",

  ///   Aircraft Statuses
  AIRCRAFT_STATUSES__BROWSE: "api/aircraft_statuses/browse",
  AIRCRAFT_STATUSES__READ: "api/aircraft_statuses/read",
  AIRCRAFT_STATUSES__EDIT: "api/aircraft_statuses/edit",
  AIRCRAFT_STATUSES__ADD: "api/aircraft_statuses/add",
  AIRCRAFT_STATUSES__DELETE: "api/aircraft_statuses/delete",

  ///   Aircraft Types
  AIRCRAFT_TYPES__BROWSE: "api/aircraft_types/browse",
  AIRCRAFT_TYPES__READ: "api/aircraft_types/read",
  AIRCRAFT_TYPES__EDIT: "api/aircraft_types/edit",
  AIRCRAFT_TYPES__ADD: "api/aircraft_types/add",
  AIRCRAFT_TYPES__DELETE: "api/aircraft_types/delete",

  ///   Batteries
  BATTERIES__BROWSE: "api/batteries/browse",
  BATTERIES__READ: "api/batteries/read",
  BATTERIES__EDIT: "api/batteries/edit",
  BATTERIES__ADD: "api/batteries/add",
  BATTERIES__DELETE: "api/batteries/delete",

  ///   Cameras
  CAMERAS__BROWSE: "api/cameras/browse",
  CAMERAS__READ: "api/cameras/read",
  CAMERAS__EDIT: "api/cameras/edit",
  CAMERAS__ADD: "api/cameras/add",
  CAMERAS__DELETE: "api/cameras/delete",

  ///   Chart Upload Items
  CHART_UPLOAD_ITEMS__BROWSE: "api/chart_upload_items/browse",
  CHART_UPLOAD_ITEMS__READ: "api/chart_upload_items/read",
  CHART_UPLOAD_ITEMS__EDIT: "api/chart_upload_items/edit",
  CHART_UPLOAD_ITEMS__ADD: "api/chart_upload_items/add",
  CHART_UPLOAD_ITEMS__DELETE: "api/chart_upload_items/delete",

  ///   Chart Uploads
  CHART_UPLOADS__BROWSE: "api/chart_uploads/browse",
  CHART_UPLOADS__READ: "api/chart_uploads/read",
  CHART_UPLOADS__EDIT: "api/chart_uploads/edit",
  CHART_UPLOADS__ADD: "api/chart_uploads/add",
  CHART_UPLOADS__DELETE: "api/chart_uploads/delete",

  ///   Clients
  CLIENTS__BROWSE: "api/clients/browse",
  CLIENTS__READ: "api/clients/read",
  CLIENTS__EDIT: "api/clients/edit",
  CLIENTS__ADD: "api/clients/add",
  CLIENTS__DELETE: "api/clients/delete",

  ///   Emergencies
  EMERGENCIES__BROWSE: "api/emergencies/browse",
  EMERGENCIES__READ: "api/emergencies/read",
  EMERGENCIES__EDIT: "api/emergencies/edit",
  EMERGENCIES__ADD: "api/emergencies/add",
  EMERGENCIES__DELETE: "api/emergencies/delete",

  ///   Emergency Items
  EMERGENCY_ITEMS__BROWSE: "api/emergency_items/browse",
  EMERGENCY_ITEMS__READ: "api/emergency_items/read",
  EMERGENCY_ITEMS__EDIT: "api/emergency_items/edit",
  EMERGENCY_ITEMS__ADD: "api/emergency_items/add",
  EMERGENCY_ITEMS__DELETE: "api/emergency_items/delete",

  ///   Folio Entries
  FOLIO_ENTRIES__BROWSE: "api/folio_entries/browse",
  FOLIO_ENTRIES__READ: "api/folio_entries/read",
  FOLIO_ENTRIES__EDIT: "api/folio_entries/edit",
  FOLIO_ENTRIES__ADD: "api/folio_entries/add",
  FOLIO_ENTRIES__DELETE: "api/folio_entries/delete",

  ///   Mission Crews
  MISSION_CREWS__BROWSE: "api/mission_crews/browse",
  MISSION_CREWS__READ: "api/mission_crews/read",
  MISSION_CREWS__EDIT: "api/mission_crews/edit",
  MISSION_CREWS__ADD: "api/mission_crews/add",
  MISSION_CREWS__DELETE: "api/mission_crews/delete",

  ///   Mission Equipment
  MISSION_EQUIPMENT__BROWSE: "api/mission_equipment/browse",
  MISSION_EQUIPMENT__READ: "api/mission_equipment/read",
  MISSION_EQUIPMENT__EDIT: "api/mission_equipment/edit",
  MISSION_EQUIPMENT__ADD: "api/mission_equipment/add",
  MISSION_EQUIPMENT__DELETE: "api/mission_equipment/delete",

  ///   Missions
  MISSIONS__BROWSE: "api/missions/browse",
  MISSIONS__READ: "api/missions/read",
  MISSIONS__EDIT: "api/missions/edit",
  MISSIONS__ADD: "api/missions/add",
  MISSIONS__DELETE: "api/missions/delete",

  ///   Permissions
  PERMISSIONS__BROWSE: "api/permissions/browse",
  PERMISSIONS__READ: "api/permissions/read",
  PERMISSIONS__EDIT: "api/permissions/edit",
  PERMISSIONS__ADD: "api/permissions/add",
  PERMISSIONS__DELETE: "api/permissions/delete",

  ///   Radios
  RADIOS__BROWSE: "api/radios/browse",
  RADIOS__READ: "api/radios/read",
  RADIOS__EDIT: "api/radios/edit",
  RADIOS__ADD: "api/radios/add",
  RADIOS__DELETE: "api/radios/delete",

  ///   Risk Management
  RISK_MANAGEMENT__BROWSE: "api/risk_management/browse",
  RISK_MANAGEMENT__READ: "api/risk_management/read",
  RISK_MANAGEMENT__EDIT: "api/risk_management/edit",
  RISK_MANAGEMENT__ADD: "api/risk_management/add",
  RISK_MANAGEMENT__DELETE: "api/risk_management/delete",

  ///   Risk Management Items
  RISK_MANAGEMENT_ITEMS__BROWSE: "api/risk_management_items/browse",
  RISK_MANAGEMENT_ITEMS__READ: "api/risk_management_items/read",
  RISK_MANAGEMENT_ITEMS__EDIT: "api/risk_management_items/edit",
  RISK_MANAGEMENT_ITEMS__ADD: "api/risk_management_items/add",
  RISK_MANAGEMENT_ITEMS__DELETE: "api/risk_management_items/delete",

  ///   Roles
  ROLES__BROWSE: "api/roles/browse",
  ROLES__READ: "api/roles/read",
  ROLES__EDIT: "api/roles/edit",
  ROLES__ADD: "api/roles/add",
  ROLES__DELETE: "api/roles/delete",

  ///   Site Infos
  SITE_INFOS__BROWSE: "api/site_infos/browse",
  SITE_INFOS__READ: "api/site_infos/read",
  SITE_INFOS__EDIT: "api/site_infos/edit",
  SITE_INFOS__ADD: "api/site_infos/add",
  SITE_INFOS__DELETE: "api/site_infos/delete",

  ///   Tasks
  TASKS__BROWSE: "api/tasks/browse",
  TASKS__READ: "api/tasks/read",
  TASKS__EDIT: "api/tasks/edit",
  TASKS__ADD: "api/tasks/add",
  TASKS__DELETE: "api/tasks/delete",

  ///   Users
  USERS__BROWSE: "api/users/browse",
  USERS__READ: "api/users/read",
  USERS__EDIT: "api/users/edit",
  USERS__ADD: "api/users/add",
  USERS__DELETE: "api/users/delete",

  ///   Weather
  WEATHER__BROWSE: "api/weather/browse",
  WEATHER__READ: "api/weather/read",
  WEATHER__EDIT: "api/weather/edit",
  WEATHER__ADD: "api/weather/add",
  WEATHER__DELETE: "api/weather/delete",
};
