<template>
  <v-app>
    <lights-loader :overlay="loader.active" :title="loader.title"></lights-loader>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <!-- <v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field> -->

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>

          <router-link :to="`/alerts`">
            <v-badge avatar bordered overlap class="px-1">
              <template v-slot:badge>
                <v-avatar>
                  {{ alerts }}
                </v-avatar>
              </template>

              <v-avatar size="24">
                <v-icon>
                  {{ icons.mdiBellOutline }}
                </v-icon>
              </v-avatar>
            </v-badge>
          </router-link>

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between"></div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from "@vue/composition-api";
import { mdiMagnify, mdiBellOutline, mdiGithub } from "@mdi/js";
import VerticalNavMenu from "./components/vertical-nav-menu/VerticalNavMenu.vue";
import ThemeSwitcher from "./components/ThemeSwitcher.vue";
import AppBarUserMenu from "./components/AppBarUserMenu.vue";
import LightsLoader from "@/components/Loader.vue";

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    LightsLoader,
  },
  // eslint-disable-next-line
  computed: {
    loader() {
      return this.$store.getters.loader;
    },
    alerts() {
      return this.$store.getters.alerts;
    },
  },
  // eslint-disable-next-line
  created() {
    this.alert();
  },
  methods: {
    async alert() {
      await this.$store.dispatch(`${this.$IYA.NAMESPACE.SYS}/${this.$IYA.ACTION.ALERTS}`);
    },
  },
  // eslint-disable-next-line
  setup() {
    const isDrawerOpen = ref(null);
    // eslint-disable-next-line
    return {
      isDrawerOpen,
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
