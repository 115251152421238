import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import "./plugins/sweet-alert";
import Vue from 'vue';
import { Upload, Dialog, Card, Row, Col, Button, TimeSelect } from 'element-ui';
import VueYoutube from "vue-youtube";
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

import "./plugins/google-maps";
import "./plugins/prototypes";

Vue.component(Upload.name, Upload);
Vue.component(Dialog.name, Dialog);
Vue.component(Card.name, Card);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Button.name, Button);
Vue.component(TimeSelect.name, TimeSelect);

Vue.config.productionTip = false;

Vue.use(VueYoutube);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
