require('./overrides.scss');

export default {
  theme: {
    themes: {
      light: {
        primary: '#1976d2',
        accent: '#0d6efd',
        secondary: '#040404',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: '#1976d2',
        accent: '#0d6efd',
        secondary: '#040404',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
    },
  },
};
