<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            LenzMedia
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>

      <div v-if="user.approved">
        <nav-menu-group v-permission="[`${perms.acl_asset}`]" title="Assets" :icon="icons.mdiElectricSwitch">
          <nav-menu-link title="Aircraft" :to="{ name: 'AircraftBrowse' }" :icon="icons.mdiDrone"></nav-menu-link>
          <nav-menu-link
            title="Battery"
            :to="{ name: 'BatteryBrowse' }"
            :icon="icons.mdiBatteryCharging50"
          ></nav-menu-link>
          <nav-menu-link title="Camera" :to="{ name: 'CameraBrowse' }" :icon="icons.mdiCamera"></nav-menu-link>
          <nav-menu-link title="Radio" :to="{ name: 'RadioBrowse' }" :icon="icons.mdiRadio"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-link
          v-permission="[`${perms.acl_mission}`]"
          title="Mission"
          :to="{ name: 'MissionBrowse' }"
          :icon="icons.mdiShieldAirplane"
        ></nav-menu-link>

        <!-- <nav-menu-link title="Permission" :to="{ name: 'PermissionBrowse' }" :icon="icons.mdiSecurity"></nav-menu-link> -->

        <nav-menu-link
          v-permission="[`${perms.acl_user}`]"
          title="Users"
          :to="{ name: 'UserBrowse' }"
          :icon="icons.mdiAccount"
        ></nav-menu-link>

        <!-- <nav-menu-link title="Weather" :to="{ name: 'WeatherBrowse' }" :icon="icons.mdiWeatherCloudy"></nav-menu-link> -->

        <nav-menu-group v-permission="[`${perms.acl_setting}`]" title="Settings" :icon="icons.mdiCogOutline">
          <nav-menu-link title="Status" :to="{ name: 'AircraftStatusBrowse' }"></nav-menu-link>
          <nav-menu-link title="Maintenance" :to="{ name: 'AircraftMaintenanceBrowse' }"></nav-menu-link>
          <nav-menu-link title="Aircraft Types" :to="{ name: 'AircraftTypeBrowse' }"></nav-menu-link>
        </nav-menu-group>
        <!-- <nav-menu-group v-permission="[`${perms.acl_system}`]" title="System" :icon="icons.mdiStateMachine">
          <nav-menu-link title="Risk Items" :to="{ name: 'RiskManagementItemBrowse' }"></nav-menu-link>
          <nav-menu-link title="Emergency Line" :to="{ name: 'EmergencyItemBrowse' }"></nav-menu-link>
          <nav-menu-link title="Mission Files" :to="{ name: 'ChartUploadItemBrowse' }"></nav-menu-link>
        </nav-menu-group> -->
        <nav-menu-link
          v-permission="[`${perms.acl_asset}`]"
          title="Alerts"
          :to="{ name: 'Alerts' }"
          :icon="icons.mdiBellCircleOutline"
        ></nav-menu-link>
      </div>
      <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>
      <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <!-- <nav-menu-group title="Pages" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Login" :to="{ name: 'pages-login' }" target="_blank"></nav-menu-link>
        <nav-menu-link title="Register" :to="{ name: 'pages-register' }" target="_blank"></nav-menu-link>
        <nav-menu-link title="Error" :to="{ name: 'error-404' }" target="_blank"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>
      <nav-menu-link title="Typography" :to="{ name: 'typography' }" :icon="icons.mdiAlphaTBoxOutline"></nav-menu-link>
      <nav-menu-link title="Icons" :to="{ name: 'icons' }" :icon="icons.mdiEyeOutline"></nav-menu-link>
      <nav-menu-link title="Cards" :to="{ name: 'cards' }" :icon="icons.mdiCreditCardOutline"></nav-menu-link>
      <nav-menu-link title="Tables" :to="{ name: 'simple-table' }" :icon="icons.mdiTable"></nav-menu-link>
      <nav-menu-link title="Form Layouts" :to="{ name: 'form-layouts' }" :icon="icons.mdiFormSelect"></nav-menu-link> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiCogOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiDrone,
  mdiBatteryCharging50,
  mdiCamera,
  mdiSecurity,
  mdiRadio,
  mdiAccount,
  mdiWeatherCloudy,
  mdiShieldAirplane,
  mdiMoonNew,
  mdiStateMachine,
  mdiElectricSwitch,
  mdiBellCircleOutline,
} from "@mdi/js";
import NavMenuSectionTitle from "./components/NavMenuSectionTitle.vue";
import NavMenuGroup from "./components/NavMenuGroup.vue";
import NavMenuLink from "./components/NavMenuLink.vue";
import { staticRoutes } from "@/router";
import permission from "@/directive/permission"; // Permission directive (v-permission)

export default {
  directives: { permission },
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    user() {
      return this.$store.getters.userinfo;
    },
    routes() {
      return staticRoutes;
    },
    perms() {
      return {
        acl_asset: this.$IYA.ACL.PERMISSION_MANAGE_ASSETS,
        acl_mission: this.$IYA.ACL.PERMISSION_MANAGE_MISSION,
        acl_user: this.$IYA.ACL.PERMISSION_MANAGE_USER,
        acl_setting: this.$IYA.ACL.PERMISSION_MANAGE_SETTINGS,
        acl_system: this.$IYA.ACL.PERMISSION_MANAGE_SYSTEM,
        acl_role: this.$IYA.ACL.PERMISSION_MANAGE_ROLE,
      };
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiCogOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiDrone,
        mdiBatteryCharging50,
        mdiCamera,
        mdiSecurity,
        mdiRadio,
        mdiAccount,
        mdiWeatherCloudy,
        mdiShieldAirplane,
        mdiMoonNew,
        mdiStateMachine,
        mdiElectricSwitch,
        mdiBellCircleOutline,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
