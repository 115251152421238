export default {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  UNAUTHORISED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER: 500,
  NOT_IMPLEMENTED: 501,
  HTTP_FAILED_DEPENDENCY: 424,
};
